import React, { useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { UserContext } from "../../App";
import { FaPercentage } from "react-icons/fa";
import axios from "axios";
import { RxCross1 } from "react-icons/rx";
import { FaTrophy } from "react-icons/fa";
import { PiSkipForwardLight } from "react-icons/pi";
import Navbar from "../Navbar";
import Footer from "../../components/Footer";
import Wrapper from "../Wrapper";
import { CiCircleCheck } from "react-icons/ci";
import { useLocation } from "react-router-dom";
import CircularGraph from "./CircularGraph";
import BarGraph from "./BarGraph";
import QuestionStatus from "./QuestionStatus";

const CompanyResult = () => {
  const location = useLocation();
  const totalQuestions = location.state ? location.state.totalQuestions : null;
  const companyPath = location.state?.companyPath;
  // console.log(totalQuestions, "ttl_no_ofQ");
  const {
    userAuth: { name, email },
    userAuth: { access_token },
  } = useContext(UserContext);
  let userId = "";
  try {
    const decodedToken = jwtDecode(access_token);
    userId = decodedToken.userId;
    // console.log("usrId", userId);
  } catch (error) {
    console.error("Error decoding JWT:", error.message);
    // console.log("Error in decoding");
  }
  const [apiData, setApiData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const decodedToken = jwtDecode(access_token);
        userId = decodedToken.userId;
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/quiz/fetch-scores/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        const data = response.data;
        setApiData(data);
        localStorage.setItem("quizResult", JSON.stringify(data));
      } catch (error) {
        console.error("Error decoding JWT or fetching data:", error.message);
      }
    };
    fetchData();
  }, [access_token, userId]);
  // console.log("apidata", apiData);

  let TotalCorrect =
    apiData?.latestTestScore?.logical?.correct +
    apiData?.latestTestScore?.programming?.correct +
    apiData?.latestTestScore?.quant?.correct +
    apiData?.latestTestScore?.verbal?.correct;

  let TotalInCorrect =
    apiData?.latestTestScore?.logical?.incorrect +
    apiData?.latestTestScore?.programming?.incorrect +
    apiData?.latestTestScore?.quant?.incorrect +
    apiData?.latestTestScore?.verbal?.incorrect;

  let TotalSkipped =
    apiData?.latestTestScore?.logical?.skipped +
    apiData?.latestTestScore?.programming?.skipped +
    apiData?.latestTestScore?.quant?.skipped +
    apiData?.latestTestScore?.verbal?.skipped;

  const accuracy = 45;
  const correctAnswers = [80, 70, 90, 10];
  const incorrectAnswers = [10, 20, 5, 0];
  const skippedAnswers = [10, 10, 5, 0];
  const questions = [
    { status: "correct" },
    { status: "incorrect" },
    { status: "skipped" },
    { status: "correct" },
    { status: "incorrect" },
    { status: "skipped" },
    { status: "correct" },
    { status: "incorrect" },
    { status: "skipped" },
    { status: "correct" },
    { status: "incorrect" },
    { status: "skipped" },
    { status: "correct" },
    { status: "incorrect" },
  ];

  return (
    <>
      <Navbar />
      <Wrapper>
        <div className="w-full p-2">
          <h1 className="text-center md:text-4xl sm:text-3xl text-2xl  mt-10 font-Nunito">
            Test Result
          </h1>

          <div className="flex flex-col   font-Nunito max-w-5xl m-auto gap-y-4">
            <div className="flex flex-wrap justify-around p-5 gap-10">
              <div className="flex-1 min-w-[300px] m-4">
                <CircularGraph
                  percentage={apiData?.latestTestScore?.percentage}
                />
              </div>
              <div className="flex-1 min-w-[300px] m-4">
                <BarGraph
                  verbal={apiData?.latestTestScore?.verbal}
                  quant={apiData?.latestTestScore?.quant}
                  logical={apiData?.latestTestScore?.logical}
                  programming={apiData?.latestTestScore?.programming}
                  totalQuestions={totalQuestions}
                />
              </div>
            </div>
            <div className="p-4">
              <QuestionStatus questions={questions} />
            </div>

            <div className="flex sm:items-center sm:my-8 items-start gap-y-4 gap-x-10 sm:flex-row flex-col justify-between ">
              <div className=" inline-flex items-center gap-x-4">
                <div className="bg-green-500  h-16 w-16 flex items-center justify-center rounded-full p-1">
                  <FaTrophy className="text-4xl  text-white rounded-full" />
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-3xl font-bold">
                    {TotalCorrect}/{totalQuestions}
                  </p>
                  <p>Correct</p>
                </div>
              </div>

              <div className=" inline-flex items-center gap-x-4">
                <div className="bg-red-400 w-16 h-16 flex items-center justify-center rounded-full p-1">
                  <RxCross1 className="text-4xl  text-white rounded-full" />
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-3xl font-bold">
                    {TotalInCorrect}/{totalQuestions}
                  </p>
                  <p>Incorrect</p>
                </div>
              </div>
              
              <div className=" inline-flex items-center gap-x-4">
                <div className="bg-blue-600 w-16 h-16 flex items-center justify-center rounded-full p-1">
                  <PiSkipForwardLight className="text-4xl  text-white rounded-full" />
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-3xl font-bold">
                    {TotalSkipped}/{totalQuestions}
                  </p>
                  <p>Skipped</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default CompanyResult;
