import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/Logo.svg'

const Sidebar = ({ searchQuery, setSearchQuery }) => {
    return (
        <aside className="w-64 bg-gray-100 p-5 border-r border-gray-300 h-screen fixed top-0 left-0">
            <div className="flex flex-col mt-24">
                {/* <Link to="/" className="text-lg text-gray-700 hover:text-gray-900">
                    <img 
                        src={logo} 
                        alt="Stairs logo" 
                        className="w-[100px] h-[100px] justify-center items-center "
                    />
                </Link> */}

                {/* Search Input */}
                <input 
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} // Update search query on input change
                    placeholder="Search blogs..." 
                    className="p-2 mb-5 border border-gray-300 rounded-md text-sm focus:outline-none"
                />
                
                {/* Create Blog Button */}
                <Link to="/create-blog" 
                    className="text-white py-2 px-4 mb-5 rounded-full text-center text-base uppercase font-bold transition-all duration-300 ease-in-out transform hover:scale-105"
                    style={{ backgroundColor: '#5BBE82' }}
                >
                    Create Blog
                </Link>
                
                {/* Sidebar Menu */}
                <ul className="space-y-4">
                    
                    <li>
                        <Link to="/blog" className="text-lg text-gray-700 hover:text-gray-900">
                            All Blogs
                        </Link>
                    </li>
                    <li>
                        <Link to="/my-blogs" className="text-lg text-gray-700 hover:text-gray-900">
                            My Blogs
                        </Link>
                    </li>
                    
                    <li className="relative group">
                        <span className="text-lg text-gray-700 hover:text-gray-900 cursor-pointer">
                            Categories
                        </span>
                        <ul className="hidden group-hover:block mt-2 pl-5 space-y-2">
                            <li className="text-sm text-gray-600 hover:text-gray-800">Category 1</li>
                            <li className="text-sm text-gray-600 hover:text-gray-800">Category 2</li>
                            <li className="text-sm text-gray-600 hover:text-gray-800">Category 3</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </aside>
    );
};

export default Sidebar;
