import React, { useEffect, useState, useRef } from 'react';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import AnimationWrapper from '../ui/animation';

const StatsInfo = () => {
  const statsRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (statsRef.current && !isVisible) {
        const elementTop = statsRef.current.getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;
        if (elementTop < viewportHeight) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  return (
    <AnimationWrapper>
      <div ref={statsRef} className="bg-gray-100 relative flex md:flex-row flex-col px-14 sm:px-16 py-8 gap-y-10 items-center justify-between rounded-sm">
        <AnimatePresence>
          {isVisible && (
            <>
              <CountItem label="Questions" end={100000} />
              <CountItem label="Unique Question Papers" end={5000} />
              <CountItem label="Students Enrolled" end={1000} />
              <CountItem label="Active Users" end={500} />
            </>
          )}
        </AnimatePresence>
      </div>
    </AnimationWrapper>
  );
};

const CountItem = ({ label, end }) => {
  const [count, setCount] = useState(end - 50); // Start counting from end - 50
  const controls = useAnimation();

  useEffect(() => {
    const startTime = Date.now();
    const endTime = startTime + 2000; // Animation completes in 2 seconds

    const interval = setInterval(() => {
      const now = Date.now();
      const progress = (now - startTime) / (endTime - startTime);
      if (progress >= 1) {
        clearInterval(interval);
        setCount(end); // Set count to the end value when animation completes
      } else {
        setCount(Math.min(count + 50 * progress, end)); // Increment count by 50 each time
      }
    }, 16); // Update every 16 milliseconds for smoother animation

    return () => clearInterval(interval);
  }, [count, end]);

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    });
  }, [controls]);

  // Format the count to display in "Lakh+"
  const formattedCount = (count >= 100000 ? '1 Lakh' : Math.floor(count).toLocaleString());

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
      exit={{ opacity: 0 }}
      className="flex flex-col items-center justify-center"
    >
      <h1 className="font-semibold md:text-4xl sm:text-3xl text-5xl font-poppins">{formattedCount}+</h1>
      <h1 className="text-sm md:text-base">{label}</h1>
    </motion.div>
  );
};

export default StatsInfo;
