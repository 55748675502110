import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import DOMPurify from "dompurify";
import axios from "axios";

const Carousel = () => {
  let {
    userAuth: { access_token },
  } = useContext(UserContext);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [topBlogs, setTopBlogs] = useState([]);

  useEffect(() => {
    const fetchTopBlogs = async () => {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/blog/get-top-blogs`;

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });

        setTopBlogs(response.data); // Array of top blogs from the backend
      } catch (error) {
        console.error("Error fetching top blogs:", error);
      }
    };

    fetchTopBlogs();
  }, [access_token]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % topBlogs.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? topBlogs.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [topBlogs.length]);

  // Edge case: No blogs available
  if (!topBlogs || topBlogs.length === 0) {
    return <div>No blogs to display.</div>;
  }

  return (
    <div
      id="carouselExampleIndicators"
      className="relative h-96 overflow-hidden shadow-lg rounded-lg"
      data-ride="carousel"
    >
      <ol className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {topBlogs.map((_, index) => (
          <li
            key={index}
            data-target="#carouselExampleIndicators"
            data-slide-to={index}
            className={`cursor-pointer w-3 h-3 rounded-full ${
              index === currentSlide ? "bg-white" : "bg-gray-500"
            }`}
            onClick={() => setCurrentSlide(index)}
          ></li>
        ))}
      </ol>

      <div className="carousel-inner h-full">
        {topBlogs.map((blog, index) => (
          <div
            className={`carousel-item transition-opacity duration-500 ease-in-out ${
              index === currentSlide ? "opacity-100" : "opacity-0"
            } absolute inset-0`} // Ensuring all slides occupy the same space
            key={blog._id}
          >
            <img
              className="d-block w-full h-full object-cover"
              src={blog.image}
              alt={blog.title}
            />
            <div className="absolute inset-0 flex flex-col justify-center items-center text-center bg-black bg-opacity-50 p-4">
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-2">
                {blog.title}
              </h1>
              <p
                className="text-lg md:text-xl text-white mb-4"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(blog.body.slice(0, 100)),
                }}
              />
              <button className="px-6 py-3 text-white bg-black rounded-lg text-base uppercase font-bold transition-all duration-300 ease-in-out transform hover:scale-105">
                Read More
              </button>
            </div>
          </div>
        ))}
      </div>

      <button
        className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white bg-black rounded-full p-2 hover:bg-gray-800 transition"
        onClick={prevSlide}
      >
        &lt;
      </button>
      <button
        className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white bg-black rounded-full p-2 hover:bg-gray-800 transition"
        onClick={nextSlide}
      >
        &gt;
      </button>
    </div>
  );
};

export default Carousel;
