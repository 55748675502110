import React, { useState } from "react";
import Wrapper from "../components/Wrapper";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
const Intruction = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleButtonClick = () => {
    if (isChecked) {
      // Navigate to main page
    }
  };

  return (
    <>
      <div className="">
        <Navbar />
        <Wrapper>
          <div className="flex  mt-8 justify-center flex-col">
            <div className="mt-2">
              <h1 className="mt-2 text-xl font-semibold">
                Read the following instructions carefully.
              </h1>
            </div>
            <div className="mt-2">
              <h2 className="sm:text-2xl text-xl font-semibold">
                General Test Instructions
              </h2>
              <ul className="list-disc ml-8">
                <li>
                  The test contains four sections, and each section is allotted
                  a specific time.
                </li>
                <li>
                  Each question has four options, of which only one is correct.
                </li>
                <li>
                  You must finish the test within 30 minutes or before that.
                </li>
                <li>
                  There is no penalty for questions that you have not attempted.
                </li>
                <li>Each correct answer will award you +1 point.</li>
                <li>There is no negative marking in the test.</li>
                <li>
                  Once you start the test, you’ll not be allowed to change the
                  browser.
                </li>
                <li className=" text-red-600">
                  If you exit the full screen mode (even by mistake) the test
                  will be auto submitted.
                </li>
              </ul>
            </div>
            <div className="mt-2">
              <h2 className="sm:text-2xl text-xl font-semibold">
                Others Test Instructions
              </h2>
              <ul className="list-disc ml-8 mt-2">
                <li>
                  The test contains all questions from the verbal section.
                </li>
                <li>
                  Each question has four options, of which only one is correct.
                </li>
                <li>
                  You must finish the test within 20 minutes or before that.
                </li>
                <li>
                  There is no penalty for questions that you have not attempted.
                </li>
                <li>Each correct answer will award you +1 point.</li>
                <li>There is no negative marking in the test.</li>
                <li>
                  Once you start the test, you’ll not be allowed to change the
                  browser.
                </li>
                <li className="text-red-600">
                  If you exit the full screen mode (even by mistake) the test
                  will be auto submitted.
                </li>
              </ul>
            </div>
            <p className="mt-2 p-2 ">
              I have read all the instructions carefully and understood them. I
              agree not to cheat or use any unfair means during this
              examination. I understand that using unfair means of any sort for
              my own or someone else’s advantage will lead to my immediate
              disqualification. The decision of STAIRS will be final in these
              matters and cannot be appealed.
            </p>
            <label className="flex items-center mt-4">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className="ml-2 inline-block ">
                I have read all the instructions carefully.
              </span>
            </label>
            <Link to="/main" className="p-2">
              <button
                className={`bg-black px-4 py-2 rounded-lg text-white ${
                  !isChecked && "opacity-50 cursor-not-allowed"
                }`}
                onClick={handleButtonClick}
                disabled={!isChecked}
              >
                I Agree
              </button>
            </Link>
          </div>
        </Wrapper>
        <Footer />
      </div>
    </>
  );
};

export default Intruction;
