// CircularGraph.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const CircularGraph = ({ percentage }) => {
  const data = {
    labels: ['Accuracy', 'Remaining'],
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(200, 200, 200, 0.5)'],
      },
    ],
  };

  const options = {
    cutout: '70%', // Creates the doughnut hole in the middle
    responsive: true,
    maintainAspectRatio: false, // Allows responsive resizing
    plugins: {
      tooltip: { enabled: false }, // Disable tooltip for cleaner appearance
      legend: { display: false },   // Remove legend since it's self-explanatory
    },
  };

  return (
    <div className="relative w-full max-w-[200px] h-auto mx-auto md:max-w-[250px] lg:max-w-[400px] xl:max-w-[400px] md:h-[250px] lg:h-[400px] xl:h-[400px]">
      <Doughnut data={data} options={options} />
      {/* Display the percentage in the middle */}
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
        {`${percentage}%`}
      </div>
    </div>
  );
};

export default CircularGraph;
