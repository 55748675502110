import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../App";
import Navbar from "../Navbar";
import toast from "react-hot-toast";
import Sidebar from "./Sidebar";
import DOMPurify from "dompurify";
import noblogs from "../../assets/noblogs.jpg";

const MyBlogsPage = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const {
    userAuth: { access_token },
  } = useContext(UserContext);

  const fetchBlogs = useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/blog/get-my-blogs`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Include token for authentication
        },
      });

      if (response.status === 404) {
        // If the status is 404, set the error state
        setError(true);
        setBlogs([]);
        setLoading(false);
      } else {
        setBlogs(response.data);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setError(true);
      }else{
        console.error("Error fetching blogs:", error);
        toast.error(error.response?.data?.message || "Failed to fetch blogs");
      }
    } finally {
      setLoading(false);
    }
  }, [access_token]);
  useEffect(() => {
    fetchBlogs();
  }, [fetchBlogs]);

  // Update blog list when a new blog is created and passed through state
  useEffect(() => {
    if (location.state?.newBlog) {
      setBlogs((prevBlogs) => [location.state.newBlog, ...prevBlogs]);
    }
  }, [location.state]);

  // Handle editing blog
  const handleEditBlog = (blog) => {
    // Navigate to the CreateBlogPage and pass the selected blog data for editing
    navigate("/create-blog", { state: { blog } });
  };

  // Handle deleting blog
  const handleDelete = async (id) => {
    const apiUrl = `${
      process.env.REACT_APP_API_BASE_URL
    }/api/blog/deleteblog/${{ blogId: id }}`;

    try {
      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${access_token}`, // Add the token in the Authorization header
          "Content-Type": "application/json",
        },
        data: {
          blogId: id, // Pass blogId in the request body as required by the backend
        },
      });

      if (response.status === 200) {
        // Filter out the deleted blog from the state
        setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog._id !== id));
        toast.success("Blog deleted successfully");
        fetchBlogs();
      }
    } catch (error) {
      console.error("Error deleting blog:", error);
      toast.error(error.response?.data?.message || "Failed to delete blog");
    }
  };

  if (loading) {
    return <p>Loading blogs...</p>;
  }

  return (
    <>
      <Navbar />
      <div className="flex max-w-full">
        <Sidebar />
        <div className="flex-1 p-6 ml-[250px]">
          <section className="mb-10">
            <h1 className="text-3xl font-bold mb-6">My Blogs</h1>
          </section>
          {error ? (
            <div className="flex max-w-full justify-center items-center flex-col">
              <img
                src={noblogs}
                alt="No blogs yet"
                className="w-96 h-96"
              />
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                You have not created any blogs yet
              </h2>
              {/* Display your custom image */}
            </div>
          ) : (
            <section className="flex flex-col gap-6">
              {blogs.map((blog) => (
                <div
                  className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col"
                  key={blog._id}
                >
                  <img
                    src={blog.image}
                    alt={blog.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h2 className="text-xl font-semibold mb-2">{blog.title}</h2>
                    <p
                      className="text-gray-600 mb-2"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(blog.body),
                      }}
                    ></p>
                    <p className="text-gray-400 text-sm mb-4">
                      Published on: {new Date(blog.createdAt).toLocaleString()}
                    </p>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleEditBlog(blog)}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(blog._id)}
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default MyBlogsPage;
