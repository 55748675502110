import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarGraph = ({ verbal = {}, quant = {}, logical = {}, programming = {}, totalQuestions = 0 }) => {
  // Provide default values to avoid undefined property access
  const defaultCategory = { correct: 0, incorrect: 0, skipped: 0 };

  // Fallback to default values if data is undefined
  const verbalData = verbal || defaultCategory;
  const quantData = quant || defaultCategory;
  const logicalData = logical || defaultCategory;
  const programmingData = programming || defaultCategory;

  // Calculating correct answers for each category
  const correctAnswers = [
    verbalData.correct,
    quantData.correct,
    logicalData.correct,
    programmingData.correct,
  ];

  const incorrectAnswers = [
    verbalData.incorrect,
    quantData.incorrect,
    logicalData.incorrect,
    programmingData.incorrect,
  ];

  const skippedAnswers = [
    verbalData.skipped,
    quantData.skipped,
    logicalData.skipped,
    programmingData.skipped,
  ];

  // Total number of questions per category (assumed to be evenly distributed for simplicity)
  const questionsPerCategory = totalQuestions / 4 || 1; // Avoid division by zero

  // Calculating correct percentage for tooltips
  const correctPercentages = correctAnswers.map((correct, idx) => {
    return ((correct / questionsPerCategory) * 100).toFixed(2);
  });

  const data = {
    labels: ['Verbal', 'Quant', 'Logical', 'Programming'],
    datasets: [
      {
        label: 'Correct Answers',
        data: correctAnswers,
        backgroundColor: 'rgba(75, 192, 192, 0.8)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderRadius: 5,
        barThickness: 25,
        categoryPercentage: 0.5,
        barPercentage: 0.9,
      },
      {
        label: 'Incorrect Answers',
        data: incorrectAnswers,
        backgroundColor: 'rgba(255, 99, 132, 0.8)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderRadius: 5,
        barThickness: 25,
        categoryPercentage: 0.5,
        barPercentage: 0.9,
      },
      {
        label: 'Skipped',
        data: skippedAnswers,
        backgroundColor: 'rgba(200, 200, 200, 0.5)',
        borderColor: 'rgba(200, 200, 200, 1)',
        borderRadius: 5,
        barThickness: 25,
        categoryPercentage: 0.5,
        barPercentage: 0.9,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'No. of Questions',
        },
        max: questionsPerCategory, // Adjusting Y-axis max to number of questions
        ticks: {
          stepSize: Math.ceil(questionsPerCategory / 4), // Dynamic step size based on questions
        },
      },
      x: {
        ticks: {
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            // Show the actual count of correct/incorrect/skipped questions
            const rawValue = context.raw;
            const index = context.dataIndex;
            let label = `${context.dataset.label}: ${rawValue} question(s)`;

            // Show correct percentage only for "Correct Answers" bar
            if (context.dataset.label === 'Correct Answers') {
              label += ` (${correctPercentages[index]}%)`;
            }

            return label;
          },
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20,
      },
    },
  };

  return (
    <div className="w-full md:w-auto lg:max-w-[600px] h-[300px] lg:h-[400px] mx-auto">
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarGraph;
