import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { UserContext } from "../../App";
import blog1 from "../../assets/blog1.jpg";
import blog4 from "../../assets/blog4.jpg";
import blog2 from "../../assets/blog2.jpg";
import blog5 from "../../assets/blog5.jpg";
import axios from "axios";
import toast from "react-hot-toast";

const CreateBlogPage = () => {
  const {
    userAuth: { access_token },
  } = useContext(UserContext);
  const location = useLocation();
  const selectedBlog = location.state?.blog || null; // Access blog if passed through navigation
  const [blogTitle, setBlogTitle] = useState("");
  const [blogImage, setBlogImage] = useState(null);
  const [blogContent, setBlogContent] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const editorRef = useRef(null); // Editor reference to capture content

  useEffect(() => {
    // Initialize Quill editor
    if (editorRef.current === null) {
      editorRef.current = new Quill("#editor", {
        theme: "snow",
        placeholder: "Compose your blog here...",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      });

      editorRef.current.on("text-change", () => {
        setBlogContent(editorRef.current.root.innerHTML);
      });
    }

    // Cleanup function to avoid memory leaks
    return () => {
      if (editorRef.current) {
        editorRef.current.off("text-change");
        editorRef.current = null;
      }
    };
  }, []);

  // Pre-fill form fields when editing a blog
  useEffect(() => {
    if (selectedBlog) {
      setBlogTitle(selectedBlog.title);
      setBlogContent(selectedBlog.body);
      setBlogImage(selectedBlog.image); // Image as URL for preview
      if (editorRef.current) {
        editorRef.current.root.innerHTML = selectedBlog.body; // Set content in Quill editor
      }
    }
  }, [selectedBlog]);

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!validImageTypes.includes(file.type)) {
      return toast.error("Unsupported image type!");
    }
    if (file.size > 5000000) {
      // 5MB limit
      return toast.error("File size exceeds 5MB");
    }
    setBlogImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = selectedBlog
      ? `${process.env.REACT_APP_API_BASE_URL}/api/blog/editblog/${selectedBlog._id}` // Update URL for editing
      : `${process.env.REACT_APP_API_BASE_URL}/api/blog/createblog`; // Create URL for new blog

    // Ensure all fields are filled
    if (!blogTitle || !blogContent || !blogImage) {
      return toast.error("All fields are required!");
    }

    // Create FormData and append the blog data
    const formData = new FormData();
    formData.append("title", blogTitle);
    formData.append("body", blogContent); // Add content from editor
    formData.append("image", blogImage); // Image is always uploaded

    try {
      setLoading(true);

      const response = selectedBlog
        ? await axios.put(apiUrl, formData, {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
          })
        : await axios.post(apiUrl, formData, {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "multipart/form-data",
            },
          });

      toast.success(
        selectedBlog
          ? "Blog successfully updated!"
          : "Blog successfully published!"
      );
      navigate("/my-blogs");
    } catch (error) {
      console.error("Error response:", error.response); // Log the full response for debugging
      if (error.response) {
        // Log error details
        console.error("Data:", error.response.data);
        console.error("Status:", error.response.status);
        toast.error(
          error.response.data?.message || "Failed to publish/update blog"
        );
      } else if (error.request) {
        console.error("Request:", error.request);
        toast.error("No response from the server");
      } else {
        console.error("Error", error.message);
        toast.error("Error in setting up request");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex justify-center items-center min-h-screen"
      style={{ backgroundColor: "#5BBE82" }}
    >
      {/* Left Images with animation */}
      <div className="flex flex-col space-y-4">
        <img
          src={blog1}
          alt="Left Image 1"
          className="w-[250px] h-[250px] object-cover rounded-lg  transition-left-image-forleftside"
          style={{ marginBottom: 20 }}
        />
        <img
          src={blog4}
          alt="Left Image 2"
          className="w-[250px] h-[250px] object-cover rounded-lg transition-right-image-forleftside"
        />
      </div>

      {/* Form Container */}
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-3xl mx-8">
        <h1 className="text-5xl font-bold text-center text-black mb-4">
          {selectedBlog ? "Edit Your Blog" : "Create Your Masterpiece"}
        </h1>
        <p className="text-lg text-gray-600 text-center mb-6">
          Share your thoughts and ideas with the world. Start your blogging
          journey now!
        </p>

        {loading && (
          <p className="text-center text-lg text-gray-500">
            {selectedBlog ? "Updating your blog..." : "Publishing your blog..."}
          </p>
        )}

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Blog Title"
            value={blogTitle}
            onChange={(e) => setBlogTitle(e.target.value)}
            required
            className="w-full p-4 mb-4 text-xl border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 transition duration-200 ease-in-out"
          />

          <div className="mb-6 flex flex-col items-start">
            <label
              htmlFor="imageUpload"
              className="inline-block px-4 py-2 text-white rounded-md cursor-pointer mb-2 transition-all duration-300 transform hover:scale-105"
              style={{ backgroundColor: "#5BBE82" }}
            >
              Upload Image
            </label>
            <input
              type="file"
              id="imageUpload"
              onChange={handleImageChange}
              accept="image/*"
              className="hidden"
            />
            {blogImage && (
              <img
                src={
                  blogImage instanceof File
                    ? URL.createObjectURL(blogImage)
                    : blogImage
                } // Handle both new and existing image
                alt="Blog Preview"
                className="mt-2 block max-w-xs h-auto border border-gray-300 rounded-md transform transition-transform duration-300 hover:scale-110"
              />
            )}
          </div>

          <div
            id="editor"
            style={{ height: "300px" }}
            className="mb-6 bg-white rounded-md shadow-sm p-4 border border-gray-200 text-left"
          ></div>

          <button
            type="submit"
            disabled={loading}
            className="text-white mt-5 px-6 py-3 rounded-md font-bold transition-all duration-300 transform hover:scale-105 shadow-lg"
            style={{ backgroundColor: "#5BBE82" }}
          >
            {loading
              ? selectedBlog
                ? "Updating..."
                : "Publishing..."
              : selectedBlog
              ? "Update Blog"
              : "Publish Blog"}
          </button>
        </form>
      </div>

      {/* Right Images with animation */}
      <div className="flex flex-col space-y-4">
        <img
          src={blog2}
          alt="Right Image 1"
          className="w-[250px] h-[250px] object-cover rounded-lg transition-right-image-forrightside"
          style={{ marginBottom: 20 }}
        />
        <img
          src={blog5}
          alt="Right Image 2"
          className="w-[250px] h-[250px] object-cover rounded-lg transition-left-image-forrightside"
        />
      </div>
    </div>
  );
};

export default CreateBlogPage;
