import React from 'react';

const QuestionStatus = ({ questions }) => {
  const renderSymbol = (status) => {
    switch (status) {
      case 'correct':
        return <span className="text-green-500">✔️</span>;
      case 'incorrect':
        return <span className="text-red-500">❌</span>;
      case 'skipped':
        return <span className="text-gray-500">➖</span>;
      default:
        return <span className="text-gray-400">❓</span>;
    }
  };
  const renderLabel = (status) => {
    switch (status) {
      case 'correct':
        return <span className="text-green-500">Correct</span>;
      case 'incorrect':
        return <span className="text-red-500">Incorrect</span>;
      case 'skipped':
        return <span className="text-gray-500">Skipped</span>;
      default:
        return <span className="text-gray-400">Unknown</span>;
    }
  };

  return (
    <div className="flex flex-wrap justify-center gap-4">
      {questions.map((question, index) => (
        <div
        key={index}
        className="flex flex-col items-center justify-center w-16 h-20 border border-gray-300 rounded-lg text-xl"
      >
        <span>Q{index + 1}: {renderSymbol(question.status)}</span>
        <span className="text-sm mt-1">{renderLabel(question.status)}</span>
      </div>
      ))}
    </div>
  );
};

export default QuestionStatus;
