import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../App";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Blog/Sidebar";
import DOMPurify from "dompurify";
import axios from "axios";
import toast from "react-hot-toast";
import Carousel from "../components/Blog/Carousel";
import HighlightSearchTerm from "../helper/highlightSearchTerm";
import CommentSection from "../components/Blog/CommentSection";

const BlogPage = () => {
  let {
    userAuth: { access_token },
  } = useContext(UserContext);
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [likesCount, setLikesCount] = useState(0);
  const [liked, setLiked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]); // State for comments
  const [showComments, setShowComments] = useState(false); // State to show/hide comments
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [filteredBlogs, setFilteredBlogs] = useState([]); // Filtered blogs based on search

  // Fetch blogs on component mount
  useEffect(() => {
    const fetchBlogs = async () => {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/blog/get-all-blogs`;
      setLoading(true);

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        setBlogs(response.data);
        setFilteredBlogs(response.data);
      } catch (error) {
        setError(error.response?.data?.message || "Failed to fetch blogs");
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [access_token, selectedBlog, comments]);

  useEffect(() => {
    // Filter blogs whenever searchQuery changes
    const filtered = blogs.filter(
      (blog) =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        blog.body.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredBlogs(filtered);
  }, [searchQuery, blogs]);

  const handleLike = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/blog/like/${selectedBlog._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setLikesCount(response.data.likesCount);
      setLiked(!liked);
    } catch (error) {
      console.error("Error liking/unliking blog:", error);
      toast.error("Failed to like/unlike the blog");
    }
  };

  // Add a new comment
  const addComment = async (newComment) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/blog/comment/${selectedBlog._id}`,
        { comment: newComment },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setComments(response.data.comments); // Update comments
    } catch (error) {
      console.error("Error adding comment:", error);
      toast.error("Failed to add comment");
    }
  };

  // Handle "Comment" button click
  const handleCommentClick = (blog) => {
    setSelectedBlog(blog);
    setShowComments(!showComments); // Toggle comment section visibility
  };

  // Handle new blog addition (if coming from state)
  useEffect(() => {
    if (location.state?.newBlog) {
      setBlogs((prevBlogs) => [location.state.newBlog, ...prevBlogs]);
    }
  }, [location.state]);

  // Truncate text function with sanitization
  const truncateText = (text, limit, searchQuery) => {
    const sanitizedText = DOMPurify.sanitize(text);
    const truncateText =
      sanitizedText.length > limit
        ? sanitizedText.substring(0, limit) + "..."
        : sanitizedText;
    return HighlightSearchTerm(truncateText, searchQuery);
  };

  // Close modal for selected blog
  const closeModal = () => {
    setSelectedBlog(null);
    setShowComments(false); // Hide comment section when modal is closed
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span>Loading blogs...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <>
      <Navbar />
      <div className="flex max-w-full">
        <Sidebar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <div className="flex-1 p-6 ml-[250px]">
          {/* Carousel Component */}
          <Carousel />

          <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-2">
            {filteredBlogs.length > 0 ? (
              filteredBlogs.map((blog, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col"
                >
                  <img
                    src={
                      blog.image ||
                      "https://www.upgrowth.in/wp-content/uploads/2018/08/successful-blog-post.png"
                    }
                    alt={blog.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-6">
                    <h2 className="text-xl font-bold mb-4">{blog.title}</h2>
                    <p className="text-gray-600 mb-4">
                      By: {blog.author?.name || "Unknown"}
                    </p>
                    <p
                      className="text-gray-600 mb-4"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          truncateText(blog.body, 100, searchQuery)
                        ),
                      }}
                    />
                    <button
                      className="text-white py-2 px-4 mb-5 rounded-lg text-center text-base uppercase font-bold transition-all duration-300 ease-in-out transform hover:scale-105"
                      style={{ backgroundColor: "#5BBE82" }}
                      onClick={() => {
                        setSelectedBlog(blog);
                        setLikesCount(blog.likes.length);
                        setComments(blog.comments);
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-lg text-gray-500">
                No blogs found.
              </p>
            )}
          </section>

          {selectedBlog && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ">
              <div className="bg-white w-full max-w-4xl h-[600px] p-6 rounded-lg relative flex ">
                <button
                  className="absolute top-2 right-4 text-gray-600 hover:text-gray-900 text-3xl z-10"
                  onClick={closeModal}
                >
                  &#10005;
                </button>
                <div
                  className={`transition-all duration-500 ${
                    showComments ? "w-[70%]" : "w-full"
                  } flex flex-col`}
                >
                  <h2 className="text-2xl font-bold mb-4">
                    {selectedBlog.title}
                  </h2>
                  <div className="overflow-y-auto">
                    <div className=" h-72 w-2/3 mb-4">
                      <img
                        src={
                          selectedBlog.image ||
                          "https://www.upgrowth.in/wp-content/uploads/2018/08/successful-blog-post.png"
                        }
                        alt={selectedBlog.title}
                        className="w-full h-full rounded-xl aspect-square"
                      />
                    </div>
                    <p
                      className="text-gray-700 mb-8 flex-1"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(selectedBlog.body),
                      }}
                    />
                    </div>
                    <div className="flex space-x-4 justify-center bg-white p-4 rounded-lg shadow-lg mt-auto">
                      <button
                        onClick={handleLike}
                        className="flex items-center space-x-1 px-4 py-2 font-bold text-white rounded-md bg-blue-500"
                      >
                        <span role="img" aria-label="like">
                          👍
                        </span>{" "}
                        Like ({likesCount})
                      </button>
                      <button
                        className="flex items-center space-x-1 px-4 py-2 font-bold text-white rounded-md bg-green-500"
                        onClick={() => handleCommentClick(selectedBlog)} // Toggle comment section
                      >
                        <span role="img" aria-label="comment">
                          💬
                        </span>{" "}
                        Comment
                      </button>
                    </div>
                  
                </div>
                {/* Conditionally render the Comment section */}
                {showComments && (
                  <div
                    className={`transition-all duration-500 absolute right-0 top-0 h-full ${
                      showComments ? "w-[30%]" : "w-0"
                    } p-4 rounded-r-lg bg-gray-100`}
                  >
                    <CommentSection
                      comments={comments}
                      onAddComment={addComment}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogPage;
