import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ScoreCard = ({ score, isLoggedIn }) => {
  const totalStairs = 5; // Five levels of score
  const stairHeight = 50; // Height of each stair
  const stairWidth = 30; // Width of each stair
  const stairGap = 10; // Gap between stairs

  const stairRanges = [
    { min: 0, max: 200 },
    { min: 201, max: 400 },
    { min: 401, max: 600 },
    { min: 601, max: 800 },
    { min: 801, max: 1000 },
  ];

  const adjustedScore = Math.max(0, Math.min(score, 1000));

  const [ballPosition, setBallPosition] = useState({ x: 0, y: 0 });
  const [activeStairs, setActiveStairs] = useState(0); // Track how many stairs are "active"
  const [isMoving, setIsMoving] = useState(false);

  const getBallPosition = () => {
    const stairIndex = stairRanges.findIndex(
      (range) => adjustedScore >= range.min && adjustedScore <= range.max
    );
    const xPosition = stairIndex * (stairWidth + stairGap) + stairWidth / 2;
    const yPosition = stairHeight * (stairIndex + 1) + 10; // Position the ball above the stair
    return { x: xPosition, y: yPosition };
  };

  const startAnimation = () => {
    setIsMoving(true);
    let direction = 1; // 1 for up, -1 for down
    let currentIndex = 0;

    const interval = setInterval(() => {
      if (currentIndex >= totalStairs - 1) {
        direction = -1; // Change direction to down
      } else if (currentIndex <= 0) {
        direction = 1; // Change direction to up
      }

      currentIndex += direction;
      const xPosition = currentIndex * (stairWidth + stairGap) + stairWidth / 2;
      const yPosition = stairHeight * (currentIndex + 1) + 10;

      setBallPosition({ x: xPosition, y: yPosition });
      setActiveStairs(currentIndex + 1); // Activate the stairs as the ball moves
    }, 500);

    return () => clearInterval(interval); // Cleanup on component unmount
  };

  useEffect(() => {
    if (!isLoggedIn) {
      startAnimation();
    } else {
      setBallPosition(getBallPosition());
      setIsMoving(false);
    }
  }, [isLoggedIn, adjustedScore]);

  return (
    <StyledWrapper>
      <div className="scorecard">
        {Array.from({ length: totalStairs }, (_, index) => (
          <div
            key={index}
            className={`stair ${
              adjustedScore >= stairRanges[index].min || index < activeStairs
                ? "active"
                : ""
            }`}
          />
        ))}
        <div
          className="ball"
          style={{
            bottom: `${ballPosition.y}px`,
            left: `${ballPosition.x}px`,
          }}
        />
      </div>
      <div >
        {isLoggedIn ? (
          <>
            <div className="scoreDisplay">Score: {score}</div>
          </>
        ) : (
          <div className="placeholder">Count Your Learning Steps Here</div>
        )}
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .scorecard {
    position: relative;
    width: 200px;
    height: 300px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: '15px',
    borderRadius: '15px',
    boxSizing: 'border-box',
  }

  .stair {
    width: 30px;
    background-color: #ecf0f1;
    border-radius: 5px;
    transition: background-color 0.3s ease, height 0.3s ease;
  }

  .stair.active {
    background-color: #5bbe82;
    transition: height 0.5s ease-in-out;
  }

  .stair:nth-child(1) {
    height: 50px;
  }

  .stair:nth-child(2) {
    height: 100px;
  }

  .stair:nth-child(3) {
    height: 150px;
  }

  .stair:nth-child(4) {
    height: 200px;
  }

  .stair:nth-child(5) {
    height: 250px;
  }

  .ball {
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #fc2403;
    border-radius: 50%;
    animation: ball-bounce 0.5s ease-in-out infinite alternate;
  }

  @keyframes ball-bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px); /* Move up */
    }
    100% {
      transform: translateY(0); /* Return to original position */
    }
  }

  .score-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    color: #333;
  }

  .score-labels span {
    font-size: 12px;
    width: 35px;
  }
    .placeholder {
    margin-top: 15px;
    font-size: 12px;
    color: #000;
    font-weight: bold;
    text-align: center;
    background-color: #f0f0f0;
    padding: 8px;
    border-radius: 7.5px;
    box-sizing: border-box;
  },
  .scoreDisplay {
    margin-top: 15px;
    font-size: 16px;
    color: #000;
    font-weight: bold;
    text-align: center;
    background-color: #f0f0f0;
    padding: 8px;
    border-radius: 7.5px;
    box-sizing: border-box;
  },
`;

export default ScoreCard;
