import React from 'react'
import Navbar from '../components/Navbar'
import Footer from "../components/Footer"
import Wrapper from '../components/Wrapper'

const UserDashboard = () => {
  return (
    <>
        <Navbar/>
           <Wrapper>
            <h1>Hello i am here to help</h1>
           </Wrapper>
        <Footer/>
    </>
  )
}

export default UserDashboard
