import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const Midleware = ({ children }) => {
    const navigate = useNavigate();
    const { userAuth } = useContext(UserContext);
    const [hasCheckedAuth, setHasCheckedAuth] = useState(false); // Add a flag to ensure checking is done once

    useEffect(() => {
        const { access_token } = userAuth;

        if (!access_token) {
            // Show toast only once when user is not logged in
            toast.error('Please login to access this page', { id: 'auth-error' }); // Adding an id to ensure it's shown only once
            navigate("/"); // Redirect to login page
        }
        setHasCheckedAuth(true); // Set this to true after checking auth
    }, [userAuth, navigate]);

    // Prevent rendering children until we have checked the user's authentication status
    if (!hasCheckedAuth) {
        return null; // Return nothing if auth check is still in progress
    }

    return <>{children}</>; // Render children only if the user is authenticated
};

export default Midleware;
