import React, { useState } from "react";
import { VscSend } from "react-icons/vsc";
import PropTypes from "prop-types";
import toast from "react-hot-toast";

const CommentSection = ({ comments, onAddComment }) => {
  const [newComment, setNewComment] = useState("");

  // Handle form submission for adding a new comment
  const handleAddComment = (e) => {
    e.preventDefault();

    if (newComment.trim() === "") {
      toast.error("Comment cannot be empty!");
      return;
    }

    onAddComment(newComment); // Add new comment through the parent function
    setNewComment(""); // Clear the input field
  };

  return (
    <div className="mt-2 ">
      <h3 className="text-lg font-semibold mb-4 ">Comments</h3>

      {/* Comment List */}
      {comments.length > 0 ? (
        <ul className="mb-4 overflow-y-auto max-h-[413px] flex-1">
          {comments.map((comment) => (
            <li key={comment._id} className="mb-2 bg-gray-100 p-2 rounded-md">
              <p className="font-bold text-gray-800">
                {comment.author.name} {/* Display author name */}
              </p>
              <p className="text-gray-800">{comment.comment}</p>{" "}
              {/* Display comment text */}
              <p className="text-gray-500 text-sm">
                {new Date(comment.date).toLocaleString()} {/* Display date */}
              </p>
              {/* You can also render replies here if needed */}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">
          No comments yet. Be the first to comment!
        </p>
      )}

      {/* Comment Input Form */}
      <form onSubmit={handleAddComment} className="relative p-3">
        <div className="relative">
          <textarea
            className="w-full p-2 pr-10 border border-gray-300 rounded-md"
            placeholder="Add your comment here..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            style={{ resize: "none" }} // Prevents resizing
          />
          <button
            type="submit"
            className="absolute bottom-2 right-2 bg-blue-500 text-white px-2 py-2 rounded-full hover:bg-blue-600 transition duration-300 ease-in-out"
          >
            <VscSend />
          </button>
        </div>
      </form>
    </div>
  );
};

CommentSection.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      comment: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      replies: PropTypes.array, // Optional, you can define further if needed
    })
  ).isRequired,
  onAddComment: PropTypes.func.isRequired, // Function to add new comment
};

export default CommentSection;
